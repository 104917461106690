(function ($) {
  Drupal.behaviors.footerPopupV1 = {
    attached: false,
    attach: function (context) {
      if (this.attached) {
        return;
      }
      this.attached = true;
      var $footerContainer = $('.js-footer-popup-v1', context);
      var $footerPopupTrigger = $('.js-footer-popup-trigger', $footerContainer);
      var $footerPopupContainer = $('.footer-popup__overlay', $footerContainer);
      var $footerPopupClose = $('.js-footer-popup-close', $footerPopupContainer);

      $footerPopupTrigger.once().on('click', function () {
        $footerPopupContainer.removeClass('hidden');
      });

      $footerPopupClose.once().on('click', function () {
        $footerPopupContainer.addClass('hidden');
      });
    }
  };
})(jQuery);
